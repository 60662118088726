<template>
  <v-card
    class="flex-card campaign-card d-flex flex-column"
    height="100%"
    :flat="flat"
  >
    <expired-timer
      v-if="invite"
      template="badge"
      :campaignInfluencer="invite"
    />
    <router-link
      :to="{
        name: 'campaign' + (!campaign.published_at ? '.create' : ''),
        params: { id: campaign.uuid },
      }"
    >
      <campaign-image
        :src="campaign.image ? campaign.image.url : campaign.image_url"
        :campaign="campaign"
        :height="185"
        class="image-border-radius campaign-list-image"
      ></campaign-image>
    </router-link>
    <v-divider></v-divider>

    <v-card-text class="grow campaign-card-content flex-grow-1">
      <div
        class="ml-2 mb-2 d-flex justify-space-between"
        :style="[isBusiness ? { height: '60px' } : { height: '76px' }]"
      >
        <div class="d-flex flex-column">
          <div
            class="subtitle-1 font-weight-medium mb-0 text--primary"
            :style="{
              overflow: 'hidden',
              display: '-webkit-box',
              webkitBoxOrient: 'vertical',
              webkitLineClamp: '2',
            }"
          >
            {{ campaign.name ? campaign.name : "Draft..." }}
          </div>

          <div v-if="!isBusiness" class="body-2 mb-0 light-grey">
            {{ campaign.business.display_name }}
          </div>
        </div>

        <div class="ml-2 mt-1 d-flex justify-space-between">
          <div class="ml-2">
            <v-img
              width="30"
              :src="'/img/flags/' + campaign.country.flag"
              :alt="campaign.country.name"
              class="rounded-sm"
            />
          </div>

          <div v-if="campaign.hidden" class="body-2 mb-0 ml-2">
            <v-icon small>fal fa-eye-slash</v-icon>
          </div>
        </div>
      </div>

      <v-list two-line>
        <!--Status-->
        <v-list-item v-if="hasField('status') && !isMobile">
          <v-list-item-action>
            <v-icon small :class="getCampaignStatus(campaign).class"
              >fas fa-circle</v-icon
            >
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="getCampaignStatus(campaign).label"></span>
            </v-list-item-title>
            <v-list-item-subtitle>Status</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--Commission-->
        <v-list-item v-if="hasField('cost_per_action')">
          <v-list-item-action>
            <v-icon color="primary">fal fa-money-bill-wave</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <template
                v-if="
                  campaign.cost_per_action_percent > 0 &&
                    campaign.cost_per_action_fixed > 0
                "
              >
                {{ campaign.cost_per_action_percent | local_numbers }}% + DKK
                {{ campaign.cost_per_action_fixed | local_numbers }}
              </template>
              <template v-else-if="campaign.cost_per_action_percent > 0">
                {{ campaign.cost_per_action_percent | local_numbers }}%
              </template>
              <template v-else-if="campaign.cost_per_action_fixed > 0">
                DKK {{ campaign.cost_per_action_fixed | local_numbers }}
              </template>
            </v-list-item-title>
            <v-list-item-subtitle>Per sale</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!isInfluencer && hasField('influencers')">
          <v-list-item-action>
            <v-icon color="primary">fal fa-users</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ campaign.participants }}
            </v-list-item-title>
            <v-list-item-subtitle>Participants</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--Start End date desktop-->
        <div v-if="!isMobile">
          <v-list-item v-if="hasField('starting_at')">
            <v-list-item-action>
              <v-icon color="primary">fal fa-calendar-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="campaign.starting_at">
                  {{
                    campaign.starting_at | utc_to_local_datetime("D MMMM YYYY")
                  }}
                </template>
                <template v-else>
                  Indefinite
                </template>
              </v-list-item-title>
              <v-list-item-subtitle>Start date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!--End date-->
          <v-list-item v-if="hasField('ending_at')">
            <v-list-item-action>
              <v-icon color="primary">fal fa-calendar-minus</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="campaign.ending_at">
                  {{
                    campaign.ending_at | utc_to_local_datetime("D MMMM YYYY")
                  }}
                </template>
                <template v-else>
                  Indefinite
                </template>
              </v-list-item-title>
              <v-list-item-subtitle>End date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!--Start End date mob-->
        <v-list-item v-if="hasField('starting_at') && isMobile">
          <v-list-item-action>
            <v-icon color="primary">fal fa-calendar-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <div
              class="d-inline-flex d-sm-inline-flex justify-start primary--text"
            >
              <div>
                <template v-if="campaign.starting_at">
                  {{
                    campaign.starting_at | utc_to_local_datetime("D MMMM YYYY")
                  }}
                </template>
                <template v-else>
                  Indefinite
                </template>
              </div>
              <span v-if="campaign.starting_at && campaign.ending_at">-</span>
              <div>
                <template v-if="campaign.ending_at">
                  {{
                    campaign.ending_at | utc_to_local_datetime("D MMMM YYYY")
                  }}
                </template>
                <template v-else>
                  Indefinite
                </template>
              </div>
            </div>
            <v-list-item-subtitle>Campaign period</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hasField('channel')">
          <v-list-item-action>
            <v-icon color="primary">fal fa-user-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ campaign.min_followers | friendly_numbers }}

              <span v-if="campaign.max_followers > 0">
                - {{ campaign.max_followers | friendly_numbers }}</span
              >
            </v-list-item-title>
            <v-list-item-subtitle>
              Follower requirements
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions v-if="$slots.footer">
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import campaign_status from "@/helpers/mixins/campaign_status";
import CampaignImage from "../images/CampaignImage";
import ExpiredTimer from "@/components/common/other/ExpiredTimer.vue";

export default {
  filters: { local_numbers, utc_to_local_datetime, friendly_numbers },
  mixins: [campaign_status],
  components: { CampaignImage, ExpiredTimer },
  props: {
    campaign: {
      required: true,
    },
    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    invite: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    showInvitation: false,
  }),
  computed: {
    ...mapGetters("core/auth", ["isBusiness", "isInfluencer"]),
    applications() {
      if (!this.campaign.influencers) {
        return [];
      }

      return this.campaign.influencers.filter((influencer) => {
        return (
          !influencer.invitation &&
          influencer.accepted_at === null &&
          influencer.declined_at === null
        );
      }).length;
    },
    participants() {
      if (!this.campaign.influencers) {
        return [];
      }

      return this.campaign.influencers.filter((influencer) => {
        return (
          influencer.accepted_at !== null && influencer.declined_at === null
        );
      }).length;
    },
    isMobile() {
      return window.innerWidth < 600 ? true : false;
    },
  },
  methods: {
    hasField(field) {
      return this.fields.length === 0 || this.fields.includes(field);
    },
    updateInvitation(campaignInfluencer, accepted) {
      this.campaign.influencers[0] = campaignInfluencer;

      if (accepted) {
        this.$emit("accepted", campaignInfluencer);
      } else {
        this.$emit("declined", campaignInfluencer);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image-border-radius {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.campaign-list-image {
  height: 185px;
}
.campaign-card-content {
  padding: 16px !important;
}
.campaign-card {
  position: relative;
  .expired-status-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;
    box-shadow: 0 0 10px rgba(1, 49, 56, 0.2) !important;
  }
}
</style>
